import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/hero'
import PageBuilder from '../components/contentTypes/pageBuilder'

const IndexPage = () => {

  const indexQuery = useStaticQuery(
    graphql`query pageQuery {
      allContentfulPage(filter: {slug: {eq: "home"}}) {
        edges {
          node {
            seoTitle
            seoDescription
            slug
            headerHero {
              ...heroFields
            }
            pageBuilder {
              ... on ContentfulSingleCallout {
                __typename
                ...callOutFields
              }
              ... on ContentfulCalloutHolder {
                __typename
                callouts {
                  ...callOutFields
                }
              }
              ... on ContentfulBeforeAfterGroup {
                __typename
                title
                resultsGroup {
                  entryTitle
                  beforeImage {
                    gatsbyImageData(width: 800, placeholder: BLURRED)
                    title
                    description
                  }
                  afterImage {
                    gatsbyImageData(width: 800, placeholder: BLURRED)
                  }
                  resultsDetails {
                    raw
                  }
                }
              }
              ... on ContentfulHeroBanner {
                __typename
                ...heroFields
              }
            }
            footerHero {
              ...heroFields
            }
          }
        }
      }
    }

    fragment buttonFields on ContentfulButton {
      title
      url
      appointment
      social
    }

    fragment heroFields on ContentfulHeroBanner {
      backgroundImage {
        gatsbyImageData(width: 2400, height: 1050, placeholder: BLURRED)
        title
        description
      }
      title
      subTitle
      heroButton {
        ...buttonFields
      }
    }

    fragment callOutFields on ContentfulSingleCallout {
      title
      caseStudies {
        entryTitle
        featuredImage {
          gatsbyImageData(width: 395, height: 220, placeholder: BLURRED)
          title
          description
        }
        date(formatString: "MMMM DD<ctrl3348>")
        content {
          raw
          references { # Add references for content
            ...assetReference
          }
        }
      }
      calloutText {
        raw
        references {
          contentful_id
          gatsbyImageData
          file {
            url
          }
          title
          description
        }
      }
      imageLink
      statistics {
        stat
        title
        icon {
          gatsbyImageData(width: 100, placeholder: BLURRED)
        }
        details {
          details
        }
      }
      button {
        ...buttonFields
      }
      backgroundColor
      extraClass
    }

    fragment assetReference on ContentfulAsset {
      contentful_id
      title
      description
      gatsbyImageData(width: 800, placeholder: BLURRED) # Or file { url } if not using gatsby-plugin-image
      __typename
    }
  `)

  const { headerHero, footerHero, pageBuilder, seoTitle, seoDescription } = indexQuery.allContentfulPage.edges[0].node;

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription && seoDescription} />
      { headerHero && <Hero data={headerHero} /> }
      { <PageBuilder data={pageBuilder} /> }
      <div className="bottomhero">{ footerHero && <Hero data={footerHero} /> }</div>
    </Layout>
  )
}

export default IndexPage